@use '../foundation/colors' as c;
@use '../foundation/mixins' as mix;
@use '../foundation/typography' as type;
.btn {
  @include mix.round-and-pad(1rem);
  color: c.$eerie-black;
  border-style: none;
  font-family: type.$font-primary;
  &:hover {
    background-color: c.$winter-sky;
    color: c.$eerie-black;
    cursor: pointer;
  }
  &:disabled {
    background-color: lighten(c.$eerie-black, 25%);
  }
  &--secondary {
    background-color: c.$eerie-black;
    color: c.$platinum;
  }
  &--submit {
    min-width: 100px;
    background-color: c.$eerie-black;
    color: c.$platinum;
    margin: 1em auto;
  }
}
