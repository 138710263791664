@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;

.social {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
  @include q.at-least-tablet {
    padding: 2rem;
  }
  &__text {
    text-align: center;
    padding-bottom: 1rem;
  }
  &__logos {
    display: flex;
    justify-content: space-evenly;
    @include q.at-least-tablet {
      margin: 0 auto;
    }
    @include q.tablet {
      width: 100%;
    }
    @include q.laptop {
      width: 50%;
    }
  }
}

#icon-fb,
#icon-ig,
#icon-behance,
#icon-ello,
#icon-github {
  font-size: 20px;
  color: c.$eerie-black;
  &:hover {
    color: c.$winter-sky;
  }
}
