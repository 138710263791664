@use './colors' as c;
@use './media-queries' as q;
@use './mixins' as mix;
@use './typography' as type;

.container {
  background-color: c.$platinum;
  color: c.$eerie-black;
  padding: 2rem;
  font-family: type.$font-primary;
  height: auto;
  overflow-x: hidden;
  &__title {
    text-align: center;
  }
  &--contact {
    @include q.at-least-tablet {
      p {
        text-align: center;
      }
      section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
