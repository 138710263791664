@use '../foundation/colors' as c;
@use '../foundation/typography' as type;
.footer {
  display: flex;
  flex-direction: column;
  background-color: c.$eerie-black;
  color: c.$platinum;
  padding: 0.5em;
  font-family: type.$font-primary;
  text-align: center;
  &__block {
    display: block;
    padding: 0.5em;
  }
}
