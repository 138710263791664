@use '../foundation/media-queries' as q;

.gallery {
  display: flex;
  flex-direction: column-reverse;
  a {
    text-decoration: none;
    flex-basis: 50%;
    flex: 100%;
    height: min-content;
    @include q.tablet {
      flex: 50%;
    }
    @include q.laptop {
      flex: 33%;
    }
  }
  @include q.at-least-tablet {
    flex-flow: row-reverse wrap;
    width: auto;
    height: 100%;
    overflow: clip scroll;
    justify-content: flex-start;
    align-content: stretch;
  }
  @include q.tablet {
    padding: 0 2rem;
  }
  @include q.laptop {
    padding: 0 5rem;
  }
}

#gallery-description {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
