@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;
@use '../foundation/mixins' as mix;

.homepage {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 273px);
  @include q.at-least-tablet {
    justify-content: space-around;
    height: fit-content;
  }
  align-items: stretch;
  padding: 0;
  width: 100%;
  background-color: c.$eerie-black;
  color: c.$platinum;
  border-bottom: 1px solid darken(c.$platinum, 20%);
  overflow-x: clip;
  &__gif {
    @include q.phone {
      height: 40vh;
    }
    @include q.tablet {
      height: 40vh;
    }
  }
  section {
    @include mix.flex-column-center;
    justify-content: center;
    padding-bottom: 2em;
    margin: auto 0;
  }
}
