@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 40vw;
  }
  @include q.at-least-tablet {
    display: grid;
    grid-template-columns: 3fr 2fr;
    img {
      width: 20vw;
    }
  }

  &__article {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 1em;
    button {
      flex-grow: 1;
      flex-basis: 10vw;
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &__photo {
      margin: 0 auto;
    }
  }
}
#cv-or-features {
  display: flex;
  justify-content: center;
  border: 1px solid c.$eerie-black;
  border-radius: 1rem;
}
