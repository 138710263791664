body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

input,
textarea {
  width: 100%;
}
