@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;
@use '../foundation/mixins' as mix;

.seriescard {
  @include q.phone {
    margin: 0.5rem;
  }
  @include q.at-least-tablet {
    margin: 0;
    padding: 0.5rem;
    position: relative;
  }
  @include q.tablet {
  }
  @include q.laptop {
  }
  &__image {
    width: 100%;
    object-fit: contain;
    @include q.laptop {
      &:hover,
      &:focus {
        filter: blur(2px);
        outline: 1px solid c.$carolina-blue;
      }
    }
  }
  &__caption {
    color: c.$eerie-black;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: none;
    @include q.at-least-tablet {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      width: 100%;
      background: none;
      color: c.$ghost-white;
      text-shadow: 2px 2px 2px c.$eerie-black;
    }
    @include q.tablet {
      font-size: 1.2rem;
    }
  }
}
