@use '../foundation/media-queries' as q;
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include q.at-least-tablet {
    display: inline-flex;
    align-items: flex-start;
    width: 50vw;
    height: 100%;
  }
  &__block {
    display: flex;
    flex-direction: column;
    @include q.at-least-tablet {
      width: 100%;
    }
  }
  &__label {
    display: block;
    padding: 1em 0;
    @include q.at-least-laptop {
    }
  }
}

#g-recaptcha {
  padding: 1rem;
  align-self: center;
}
