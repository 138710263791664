@use '../foundation/mixins' as mix;
.errorpage {
  @include mix.flex-column-center;
  h1,
  h2 {
    text-align: center;
  }
  figure {
    margin: auto;
    padding: 1rem;
    max-width: 300px;
    img {
      width: 100%;
    }
    figcaption {
      text-align: center;
    }
  }
}
