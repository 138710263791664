@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;
@use '../foundation/mixins' as mix;
@use '../foundation/typography' as type;

.textarea {
  @include mix.round-and-pad(1rem);
  border: 1px solid c.$eerie-black;
  font-size: small;
  font-family: type.$font-primary;
  resize: none;
  width: auto;
}

textarea.textarea {
  height: 200px;
}
