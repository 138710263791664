@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;
@use '../foundation/mixins' as mix;
@use '../foundation/typography' as type;

@mixin laptop-style-links {
  display: flex;
  flex-direction: row;
  width: max-content;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0.5rem 0;
  a {
    @include mix.nav-link-style(c.$platinum);
    margin-left: 0.5rem;
    &:hover {
      color: c.$winter-sky;
      text-decoration: underline;
    }
    @include q.at-least-laptop {
      margin-left: 2rem;
    }
  }
}

.navbar {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: 'header' 'hidden-links';
  background-color: c.$eerie-black;
  font-family: type.$font-primary;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  @include q.phone {
    padding: 0.5rem 1rem;
    z-index: 2;
  }
  @include q.at-least-tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    display: block;
    width: 20vw;
    @include q.phone {
      padding: 1em 0;
      width: 30vw;
    }
    @include q.tablet {
      width: 20vw;
    }
    @include q.laptop {
      width: 15vw;
    }
  }
  &__hamburger-btn {
    display: block;
    background: none;
    border: none;
    * {
      color: c.$platinum;
    }
    @include q.at-least-tablet {
      display: none;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    grid-area: hidden-links;
    list-style: none;
    padding: 0;
    li {
      padding: 1rem 0;
    }
    a {
      @include mix.nav-link-style(c.$platinum);
    }
    &--hidden {
      display: none;
    }
    @include q.at-least-tablet {
      @include laptop-style-links;
      &--hidden {
        @include laptop-style-links;
      }
    }
  }
  .active {
    color: c.$winter-sky;
    font-weight: bold;
  }
}

.mobile-navbar-filler {
  display: none;
  @include q.phone {
    display: block;
    border: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
    background: fixed;
    background-color: c.$eerie-black;
    opacity: 0.5;
    &:focus {
      outline: 1px solid c.$carolina-blue;
    }
  }
}
