@use '../foundation/colors' as c;
@use '../foundation/media-queries' as q;

.series {
  display: flex;
  flex-direction: column;
  width: fit-content;
  @include q.at-least-tablet {
    flex-flow: row wrap;
    flex-basis: 330px;
    justify-content: space-evenly;
  }
  @include q.laptop {
    width: 90%;
    margin: 0 auto;
  }
  &__artwork {
    padding: 0;
    @include q.at-least-tablet {
      max-width: 30%;
    }
    &__picture {
      width: 100%;
      height: auto;
      user-select: none;
    }
    &__title {
      text-align: center;
    }
  }
}
