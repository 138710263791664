//this one's good for buttons and text fields
@use './media-queries' as q;

@mixin round-and-pad($size) {
  border-radius: $size;
  padding: $size;
}

@mixin nav-link-style($color) {
  list-style: none;
  text-decoration: none;
  color: $color;
  padding: 1em 0;
  @include q.at-least-tablet {
    padding: 0;
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
